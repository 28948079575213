dmx.Component('view', {

    initialData: {
        loading: false
    },

    tag: 'div',

    events: {
        error: Event,
        unauthorized: Event,
        forbidden: Event,
        notfound: Event
    },

    render: function(node) {
        this.xhr = new XMLHttpRequest();
        this.xhr.addEventListener('load', this.onload.bind(this));
        this.xhr.addEventListener('abort', this.onabort.bind(this));
        this.xhr.addEventListener('error', this.onerror.bind(this));
        this.xhr.addEventListener('timeout', this.ontimeout.bind(this));
        this.url = location.pathname;
    },

    update: function(props) {
        var url = location.pathname;

        if (this.url == url && this.data.loading) {
            // Url is loading
            return;
        }

        if (this.url != url) {
            // correctly destroy old content first
            this.$node.innerHTML = '';
            this.children.splice(0).forEach(function(child) {
                child.$destroy();
            });

            this.set('loading', true);

            this.url = url;

            this.xhr.abort();
            this.xhr.open('GET', url);
            this.xhr.setRequestHeader('accept', 'text/fragment+html');
            this.xhr.send();
        }
    },

    onload: function(event) {
        this.set('loading', false);
        if (this.xhr.status == 200 || this.xhr.status == 0) {
            this.$node.innerHTML = this.xhr.responseText;
            this.$parse(this.$node);
        } else {
            if (this.xhr.status == 401) {
                this.dispatchEvent('unauthorized');
            } else if (this.xhr.status == 403) {
                this.dispatchEvent('forbidden');
            } else if (this.xhr.status == 404) {
                this.dispatchEvent('notfound');
            } else {
                this.dispatchEvent('error');
            }
        }
    },

    onabort: function(event) {
        this.set('loading', false);
    },

    onerror: function(event) {
        this.set('loading', false);
        this.dispatchEvent('error');
    },

    ontimeout: function(event) {
        this.set('loading', false);
        this.dispatchEvent('error');
    }

});